<template>
  <section id="overhead-document">
    <!-- <breadcrumbs-movement :extra="movementDocument" /> -->
    <movement-head
      :document="movementDocument"
      :is_storage_error="is_storage_error"
      :is_target_storage_error="is_target_storage_error"
      :loading="loading"
      @set_is_storage_error="set_is_storage_error"
      @onChange="updateHead"
      @makeUsed="makeUsed"
    />
    <movement-spec-table
      :document="movementDocument"
      :busy="tableIsBusy"
      @set_is_storage_error="set_is_storage_error"
      @update_table="refetchData"
      @scroll-down="scrollDown"
      @scroll-up="scrollUp"
      @get-last="getLast"
      @get_by_order="getByOrder"
      @update-data="updateData"
      @storage_error="setStorageError"
      @search="search"
    />
  </section>
</template>

<script>
  import MovementSpecTable from '@/views/operational-processes/components/movement/MovementSpecTable'
  import MovementHead from '@/views/operational-processes/components/movement/MovementHead'
  import { MovementHeadModel } from '@/views/operational-processes/models/movement-head.model'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Specifications',
    components: { MovementSpecTable, MovementHead },
    data() {
      return {
        movementDocument: new MovementHeadModel(),
        tableIsBusy: true,
        loading: false,
        pagination: {
          skip: 0,
          take: 10,
          order: [{ key: 'order', value: 'ASC' }]
        },
        forwardDirection: false,
        first: true,
        is_storage_error: false,
        is_target_storage_error: false
      }
    },
    apollo: {
      MovementHead: {
        query: require('../../gql/getMovementHead.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: this.pagination
          }
        },
        result({ data }) {
          this.tableIsBusy = false
          const oldspec = !this.first ? this.movementDocument.paginationSpecifications.specification || [] : []
          this.movementDocument = new MovementHeadModel(data.MovementHead)
          if (!this.movementDocument?.entity?.id) {
            if (this.currentBranch?.entities?.length === 1) {
              this.movementDocument.setEntity(this.currentBranch?.entities[0])
            }
          }
          this.setBreadcrumbs({ ...data.MovementHead, is_go_back: true })
          if (!this.first) {
            // const newDoc = new MovementHeadModel(data.MovementHead)
            if (!this.forwardDirection) {
              this.movementDocument.paginationSpecifications.specification.unshift(...oldspec)
            } else {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.movementDocument.paginationSpecifications.specification.push(...oldspec)
              if (oldspec.length > 0)
                this.$nextTick(() => {
                  document.querySelector('.table-docs').scrollTop =
                    document.querySelector('.table-docs').scrollHeight - initialHeight - 10
                })
            }
          }

          this.isUpdateFilters = false
          this.first = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        }
      }
    },
    computed: {
      ...mapGetters({ currentBranch: 'settings/getCurrentBranch' })
    },
    watch: {
      movementDocument() {
        this.$nextTick(() => {
          const table = document.querySelector('.table-docs')
          if (table.scrollHeight === table.clientHeight) {
            this.scrollDown()
          }
        })
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      set_is_storage_error(el) {
        if (el) {
          this.is_storage_error = false
        } else {
          this.is_storage_error = true
        }
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getMovementHead.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: pagination
          }
        })
        this.getHead(data)
      },
      getHead(data) {
        this.tableIsBusy = false
        const oldspec = !this.first ? this.movementDocument.paginationSpecifications.specification || [] : []
        this.movementDocument = new MovementHeadModel(data.MovementHead)
        if (!this.movementDocument?.entity?.id) {
          if (this.currentBranch?.entities?.length === 1) {
            this.movementDocument.setEntity(this.currentBranch?.entities[0])
          }
        }
        if (!this.first) {
          // const newDoc = new PaymentHeadModel(data.IncomingHead)
          if (!this.forwardDirection) {
            this.movementDocument.paginationSpecifications.specification.unshift(...oldspec)
          } else {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            this.movementDocument.paginationSpecifications.specification.push(...oldspec)
            if (oldspec.length > 0)
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
          }
        }

        this.isUpdateFilters = false
        this.first = false
      },
      setStorageError() {
        this.is_storage_error = true
        this.$noty.show(`Необходимо указать отправителя`)
      },
      updateHead() {
        if (this.movementDocument.id) {
          this.$apollo.mutate({
            mutation: require('../../gql/UpdateMovementDocument.gql'),
            variables: this.movementDocument.input
          })
        }
      },
      async refetchData(cb) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getMovementHead.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: {
              skip: this.movementDocument.paginationSpecifications.total,
              take: 1,
              order: [{ key: 'order', value: 'ASC' }]
            }
          }
        })
        const oldspec = this.movementDocument.paginationSpecifications.specification || []
        this.movementDocument = new MovementHeadModel(data.MovementHead)
        this.movementDocument.paginationSpecifications.specification.unshift(...oldspec)
        if (cb) cb()
      },
      updateData() {
        this.first = true
        this.pagination.skip = 0
        this.$apollo.queries.MovementHead.refetch()
      },
      async scrollDown() {
        const lastItem =
          this.movementDocument.paginationSpecifications.specification?.[
            this.movementDocument.paginationSpecifications.specification?.length - 1
          ]
        if (!lastItem) return
        const pagination = this.pagination
        this.forwardDirection = false
        pagination.skip = lastItem.order
        pagination.take = this.pagination.take
        if (pagination.skip >= this.movementDocument.paginationSpecifications.total) {
          return
        }
        this.pagination = pagination
        await this.$apollo.queries.MovementHead.refresh()
        this.pagination.take = 10
      },
      async scrollUp() {
        const firstElement = this.movementDocument.paginationSpecifications.specification?.[0]
        if (!firstElement || firstElement?.order <= 2) return
        const pagination = Object.assign(this.pagination)
        this.forwardDirection = true
        pagination.skip = firstElement.order - pagination.take - 2
        if (pagination.skip < 0) {
          pagination.take = pagination.take + pagination.skip
          pagination.skip = 0
        }
        this.fetchHead(pagination)
      },
      async getLast(cb) {
        this.forwardDirection = true
        const pagination = Object.assign(this.pagination)
        pagination.skip = this.movementDocument.paginationSpecifications.total - pagination.take + 1
        if (pagination.skip < 0) pagination.skip = 0
        this.first = true
        await this.fetchHead(pagination)

        if (cb) cb()
      },
      async getByOrder(spec, cb) {
        const pagination = this.pagination
        pagination.skip = spec.order - 2
        if (pagination.skip > this.movementDocument.paginationSpecifications.total - pagination.take)
          pagination.skip = this.movementDocument.paginationSpecifications.total - pagination.take
        this.first = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      async makeUsed(status) {
        this.loading = true
        try {
          this.is_target_storage_error = !this.movementDocument?.targetStorage?.id
          this.is_storage_error = !this.movementDocument?.storage?.id
          if (!this.movementDocument?.targetStorage?.id || !this.movementDocument?.storage?.id) {
            return
          }
          await this.$apollo.mutate({
            mutation: require('../../gql/UpdateMovementDocument.gql'),
            variables: {
              id: this.movementDocument.id,
              status: 'awaiting'
            }
          })
          this.movementDocument.fixed = status
          await this.updateData()
        } catch (e) {
          this.loading = false
          this.$noty.error('Ошибка на сервере')
        } finally {
          this.loading = false
        }
      },
      async search(value) {
        this.pagination.search = value
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.updateData(), 500)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #overhead-document {
      display: flex;
      flex-direction: column;
      height: 100% !important;
      flex: 1;
      min-height: 1px;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1 !important;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1 !important;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1 !important;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100% !important;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100% !important;
      overflow-x: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        width: 2000px;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px !important;
            vertical-align: middle;
            text-align: center;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        //position: relative;
        width: 100%;
        z-index: 10;

        & > div {
          position: fixed;
        }
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }
        }
      }
    }

    .b-table__navbar {
      margin: -85px 15px 0;
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }

  #overhead-document {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .table-docs {
      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
</style>
