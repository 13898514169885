<template>
  <div class="b-table__wrapper">
    <modal-costs-add @costs_add="totalUpdateCosts" />
    <modal-price-up @price_up="totalUpdateMarkup" />
    <modal-print-price-tags :products="printProducts" />
    <div class="b-table__content mt-3">
      <div class="table-filter">
        <b-form-input
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="search"
        />
        <b-button
          class="btn-more ml-3"
          @click="openSortModal"
        >
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="table-inner">
        <b-table-simple
          ref="movement_table"
          :items="document.paginationSpecifications.specification"
          hover
          class="table-docs table-fixed"
          fixed
          sticky-header
          no-border-collapse
          table-class="movement-table w-100"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(td, index) in fields.filter((el) => el.checked)"
                :key="index"
                :class="td.class + ' field-column unselectable text-center'"
                :style="{
                  'min-width': td.width + 'px',
                  width: td.width + 'px'
                }"
              >
                {{ td.label }}
                <img
                  v-if="td.sortable"
                  src="/img/icons/icon-sorting.svg"
                  class="cursor img-sorting"
                  alt=""
                />
                <b-form-checkbox
                  v-if="td.key === 'id'"
                  v-model="isAllSelected"
                />
                <div
                  class="resize-field"
                  @mousedown="(el) => mouseDown(el, td)"
                >
                  &nbsp;
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody class="movement-table-body">
            <b-tr v-if="busy">
              <td :colspan="fields.length">
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Загрузка..."
                  />
                  <div class="mt-2"><strong>Загрузка...</strong></div>
                </div>
              </td>
            </b-tr>

            <b-tr
              v-for="(tr, tr_index) in document.paginationSpecifications.specification"
              :key="tr_index"
            >
              <b-td
                v-for="(field, index) of fields.filter((el) => el.checked)"
                :key="index"
                style="overflow: hidden"
              >
                <template v-if="field.key === 'id'">
                  <b-form-checkbox
                    v-model="selected"
                    class="cursor"
                    :value="tr.id"
                  />
                </template>
                <template v-if="field.key === 'order'">
                  {{ tr.order }}
                </template>
                <template v-if="field.key === 'product'">
                  <product-spec-info :product="tr.product" />
                </template>
                <template v-if="field.key === 'delta'">
                  <table-row-input
                    :ref="'delta' + tr.id"
                    :value="tr.delta"
                    :fixed="document.fixed"
                    :input_id="'delta' + tr.id"
                    :digits="3"
                    @handle-input="(val) => setDelta(val, tr)"
                    @input="(val) => setDelta(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'measurement'">
                  {{ tr.product.measurement.name || '–' }}
                </template>

                <template v-if="field.key === 'price'">
                  <table-row-input
                    :value="tr.price"
                    :fixed="document.fixed"
                    :input_id="'price' + tr.id"
                    symbol="₽"
                    @handle-input="(val) => setPrice(val, tr)"
                    @input="(val) => setPrice(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'sum'">
                  <table-row-input
                    :value="tr.sum"
                    :fixed="document.fixed"
                    :input_id="'sum' + tr.id"
                    symbol="₽"
                    @handle-input="(val) => setSum(val, tr)"
                    @input="(val) => setSum(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'markup'">
                  <table-row-input
                    :value="tr.markup"
                    :fixed="document.fixed"
                    :input_id="'markup' + tr.id"
                    symbol="%"
                    @handle-input="(val) => setMarkup(val, tr)"
                    @input="(val) => setMarkup(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'retailPrice'">
                  <table-row-input
                    :value="tr.retailPrice"
                    :fixed="document.fixed"
                    :input_id="'retailPrice' + tr.id"
                    symbol="₽"
                    @handle-input="(val) => setRetailPrice(val, tr)"
                    @input="(val) => setRetailPrice(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'currentPrice'">
                  {{ formatPrice(tr.product?.retailPrice ?? 0) }} ₽
                </template>

                <template v-if="field.key === 'nds'">
                  <table-select-input
                    :option_list="taxRateList"
                    :value="tr.nds.id"
                    :fixed="document.fixed"
                    :input_id="'nds' + tr.id"
                    symbol="₽"
                    @handle-input="(val) => setNds(val, tr)"
                    @input="(val) => setNds(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'taxRateSum'"> {{ tr.taxRateSum }} ₽ </template>

                <template v-if="field.key === 'remainders'">
                  {{ getRemainder(tr.product.remainders) }}
                </template>

                <template v-if="field.key === 'costs'">
                  {{ +tr.cost?.toFixed(2) || 0 }}
                </template>
              </b-td>
            </b-tr>

            <b-tr v-if="document.paginationSpecifications?.specification?.length === 0 && !busy">
              <td :colspan="fields.length">
                <p class="center mt-3">Нет товаров</p>
              </td>
            </b-tr>
            <!--            <add-movement-spec-->
            <!--              v-if="handler_product"-->
            <!--              :spec="specToCreate"-->
            <!--              @add_product="createSpec"-->
            <!--              @remove_prod="removeProduct"-->
            <!--              @on-focus="centeredScroll"-->
            <!--              :current_storage_id="document.storage.id"-->
            <!--            />-->
            <b-tr v-if="!document.fixed && !scrollExist">
              <b-td :colspan="fields.filter((el) => el.checked).length">
                <div class="d-flex">
                  <product-search
                    v-if="!document.fixed && !scrollExist"
                    :document_head="document.id"
                    :document="document"
                    :document_type="'incoming'"
                    @select_product="select_product"
                  />
                </div>
              </b-td>
            </b-tr>
            <movement-navbar
              style="position: absolute; bottom: 30px; right: 16px; left: 16px"
              :items="isAllSelected ? document?.paginationSpecifications?.total : selected?.length ?? 0"
              :clear_selected="clearSelected"
              @edit_product="edit_product"
              @remove_items="removeSpecs"
              @print_price_tags="printPriceTags"
            />
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="table-footer movement-table-footer align-middle d-flex align-items-center p-3">
        <div style="width: 45px"><span></span></div>
        <div style="width: 45px"><span></span></div>
        <div class="mr-auto">
          <product-search
            v-if="!document.fixed && scrollExist"
            style="width: 250px"
            class="ml-2"
            :document_head="document.id"
            :document="document"
            :document_type="'movement'"
            @select_product="select_product"
          />
        </div>

        <div class="footer-data">
          Итого:
          {{ document.paginationSpecifications?.total }}
        </div>

        <div class="footer-data">
          Количество:
          <span>
            {{ (+document.paginationSpecifications?.factTotal?.toFixed(2))?.toLocaleString('ru-RU') || 0 }}
          </span>
        </div>

        <div class="footer-data">
          Сумма:
          <span>
            {{ formatPrice(document.paginationSpecifications?.sumTotal) }}
            ₽
          </span>
        </div>
      </div>
    </div>
    <sort-table-modal
      :fields="copyFields"
      :default_fields="default_fields"
      @change="changeFields"
      @set-default="setDefault"
    />
  </div>
</template>

<script>
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import MovementNavbar from '@/views/operational-processes/components/movement/MovementNavbar'
  // import { bus } from '@/main'
  import { mapGetters } from 'vuex'
  import ModalCostsAdd from '@/views/operational-processes/components/overhead/ModalCostsAdd'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import TableSelectInput from '@/views/operational-processes/components/TableSelectInput'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import { MovementSpecificationModel } from '@/views/operational-processes/models/movement-spec.model'
  import { MovementHeadModel } from '@/views/operational-processes/models/movement-head.model'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import { ProductModel } from '@/models/product.model'
  import ProductInfo from '@/views/operational-processes/components/cashbox/ProductInfo.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'

  export default {
    components: {
      ProductSpecInfo,
      ProductInfo,
      ProductSearch,
      SortTableModal,
      TableSelectInput,
      TableRowInput,
      ModalPrintPriceTags,
      ModalPriceUp,
      ModalCostsAdd,
      MovementNavbar
    },
    props: {
      document: {
        type: MovementHeadModel
      },
      busy: {
        type: Boolean
      }
    },
    data() {
      return {
        isUpdateFilters: false,
        searchString: '',
        search_result: false,
        fields: [],
        printProducts: [],
        specToCreate: new MovementSpecificationModel(),
        handler_product: false,
        default_fields: [
          {
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 54
          },
          {
            key: 'product',
            label: 'Наименование товара',
            thStyle: 'width: 250px ;min-width: 250px;',
            checked: true,
            width: 650
          },
          {
            key: 'delta',
            label: 'Общее количество',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 120
          },
          {
            key: 'measurement',
            label: 'Единица измерения',
            thStyle: 'min-width: 54px;width: 54px',
            checked: true,
            width: 120,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 140,
            formatter: (value, key, item) => item.product?.retailPrice ?? 0
          },
          {
            key: 'remainders',
            label: 'Остаток',
            formatter: (value, key, item) => this.getRemainder(item.product.remainders),
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          }
        ],
        selected: [],
        isAllSelected: false,
        taxRateList: [],
        thElm: 0,
        startOffset: 0,
        field: null,
        scrollExist: true,
        isModalOpened: false
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      copyFields() {
        return JSON.parse(JSON.stringify(this.fields))
      },
      getProduct() {
        return this.document?.paginationSpecifications?.specification?.find((el) => el.id === this.selected[0])?.product
      }
    },

    watch: {
      isAllSelected(newVal) {
        if (newVal) {
          this.selected = this.document.paginationSpecifications?.specification?.map((el) => el.id) ?? []
        } else this.selected = []
      }
    },

    mounted() {
      this.$nextTick(() => this.tableResize())
      const srtFields = localStorage.getItem('movement_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : this.default_fields
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )

      this.loadFields()
      this.$nextTick(() => this.scrollListener())
    },

    destroyed() {
      this.isUpdatedStorage = false
      document.removeEventListener('click', () => console.info('removeListener'))
    },

    methods: {
      edit_product() {
        this.$router.push({
          name: 'products.edit',
          params: { name: this.getProduct.name, id: this.getProduct.id }
        })
      },
      scrollDown(event) {
        let bottomOfWindow = event.target.scrollTop + 100 + event.target.clientHeight >= event.target.scrollHeight
        if (event.target.scrollTop === 0) {
          this.$emit('scroll-up', bottomOfWindow)
        } else if (bottomOfWindow) {
          this.$emit('scroll-down')
        }
      },
      async loadFields() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'movement')
        if (table?.fields) this.fields = table.fields
      },
      scrollListener() {
        const resizeObserver = new ResizeObserver(() => {
          const table = document.querySelector('.table-docs')

          this.scrollExist =
            table.offsetHeight <= document.querySelector('.movement-table')?.clientHeight ||
            (table.offsetHeight <= document.querySelector('.movement-table')?.clientHeight + 55 && this.scrollExist)
        })

        resizeObserver.observe(document.querySelector('.movement-table'), {
          childList: true,
          subtree: true
        })
        document.querySelector('.table-docs').addEventListener('scroll', this.scrollDown)
        // window.removeEventListener('scroll', this.handleScroll)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      tableResize() {
        document.addEventListener('mousemove', (e) => {
          if (this.thElm && this.startOffset + e.pageX > 0) {
            this.thElm.style.minWidth = this.startOffset + e.pageX + 'px'
            this.thElm.style.width = this.startOffset + e.pageX + 'px'
            this.field.width = this.startOffset + e.pageX
          }
        })

        document.addEventListener('mouseup', () => {
          if (!this.thElm) return
          this.field.thStyle = this.thElm.style.cssText
          this.saveFields()
          this.startOffset = 0
          this.thElm = undefined
        })
      },
      setDefault() {
        this.fields = this.default_fields
        this.saveFields()
      },
      saveFields() {
        localStorage.setItem('movement_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'movement',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }

        return 0
      },

      setDelta(val, item) {
        item.setDelta(val)
        this.updateSpec(item)
      },

      setPrice(val, item) {
        item.setPrice(val)
        this.updateSpec(item)
      },

      setSum(val, item) {
        item.setSum(val)
        this.updateSpec(item)
      },

      setMarkup(val, item) {
        item.setMarkup(val)
        this.updateSpec(item)
      },

      setRetailPrice(val, item) {
        item.setRetailPrice(val)
        this.updateSpec(item)
      },

      setNds(val, item) {
        item.setNds(val)

        this.updateSpec(item)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },

      async select_product(item) {
        if (item.specification) {
          if (!this.$refs['delta' + item.specification.id][0]) {
            this.$emit('get_by_order', item.specification, () => this.scroll_to(item))
          } else {
            this.scroll_to(item)
          }

          return
        }
        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0
        if (item.found_by_barcode && item.specification)
          delta = item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount

        if (item.brutto && item.found_by_barcode) delta = item.brutto

        const new_spec = new MovementSpecificationModel({
          ...item.specification,
          delta: item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount,
          product: item,
          document_head: this.document,
          nds: item.taxRate
        })

        new_spec.setDelta(delta)
        new_spec.setPrice(item.purchasePrice)
        this.createSpec(new_spec.input)
      },
      async createSpec(spec) {
        if (this.document.storage.id) {
          const { data } = await this.$apollo.mutate({
            mutation: require(`../../gql/CreateMovementSpecification.gql`),
            variables: {
              productChange: [spec],
              document_head: this.document.id
            }
          })
          this.$emit('get-last', () => {
            setTimeout(() => {
              document.querySelector('.table-docs').scrollTo({
                top: document.querySelector('.table-docs').scrollHeight,
                left: 0
              })
            }, 500)
            this.$nextTick(() => {
              this.$refs['delta' + data.CreateMovementSpecification[0].id][0].showInput()
            })
          })
          this.$noty.show('Добавлено')
          document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
        } else {
          this.$emit('set_is_storage_error')
        }
      },

      scroll_to(item) {
        const spec = this.document.paginationSpecifications.specification.find((el) => el.id === item.specification.id)
        if (item.found_by_barcode && item.brutto) {
          spec.setDelta(spec.delta + item.brutto)
          this.updateSpec(spec)
          return
        }

        setTimeout(() => {
          this.$refs['delta' + item.specification.id][0].showInput()
        }, 100)
        document.querySelector('.table-docs').scrollTo({
          top: this.$refs['delta' + item.specification.id][0].$el.offsetTop - 80
        })
      },

      async updateSpec(spec) {
        await this.$apollo.mutate({
          mutation: require(`../../gql/CreateMovementSpecification.gql`),
          variables: {
            productChange: [spec.input],
            document_head: this.document.id
          }
        })
      },

      async removeSpecs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/RemoveMovementSpecification.gql'),
          variables: {
            document_head: this.document.id,
            ids: this.selected,
            isAllSelected: this.isAllSelected
          }
        })
        if (data?.RemoveMovementSpecification?.status) {
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },

      removeProduct() {
        this.specToCreate = {}
        this.handler_product = false
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },
      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },
      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true
      },

      async printPriceTags() {
        let products = []
        for (const prod of this.selected) {
          const found = this.document.paginationSpecifications.specification.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
          }
        }
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.document.id,
              branch: this.document.branch.id,
              product: products,
              operation: 'movement',
              isPrintAll: this.isAllSelected
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      changeFields(val) {
        this.fields = val
        this.saveFields()
      },

      showPriceUp() {
        this.$bvModal.show('modal-price-up')
      },

      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddMarkup.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      async totalUpdateCosts(costsadd) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddCosts.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              costs: +costsadd.value,
              addParams: costsadd.addParams
            }
          }
        })
        if (data?.AddCosts?.status) {
          this.$bvModal.hide('modal-costs-add')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      search(value) {
        this.$emit('search', value)
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .table-footer th {
      vertical-align: middle !important;
    }
    .article {
      font-size: 12px;
      line-height: 24px;
      color: #888;
      margin-top: 5px;
    }
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    & .count {
      background-color: transparent;
      transition: none ease-in;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }
  .movement-table {
    table-layout: fixed;
    width: 100% !important;
  }
  table.fixed td {
    overflow: hidden;
  }
  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
  }

  .movement-table {
    border: none !important;
  }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }
  .movement-table-footer {
    border-top: 1px solid #e1e1e1 !important;
  }
</style>
