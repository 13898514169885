<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  :clearable="false"
                  :is-keyup="true"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Отправитель"
              label-for="storage"
              :class="{ error: is_storage_error }"
            >
              <template v-if="fixed">
                {{ filter_params.storage.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.storage.id"
                  transfer
                  @on-change="changeStorage"
                >
                  <i-option
                    v-for="item in storage_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Получатель"
              label-for="storage"
              :class="{ error: is_target_storage_error }"
            >
              <template v-if="fixed">
                {{ filter_params.targetStorage.name }}
              </template>
              <template v-else>
                <i-select
                  id="targetStorage"
                  transfer-class-name="my-dropdown"
                  :value="filter_params.targetStorage.id"
                  transfer
                  @on-change="changeTargetStorage"
                >
                  <i-option-group
                    v-for="item in branchList"
                    :key="item.id"
                    class="option-group"
                    :value="item.id"
                    :label="item.name"
                  >
                    <i-option
                      v-for="el in item.storage"
                      :key="el.id"
                      :value="el.id"
                    >
                      {{ el.name }}
                    </i-option>
                  </i-option-group>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    @input="setComment"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  // import { bus } from '@/main'
  import { mapGetters } from 'vuex'
  import { MovementHeadModel } from '@/views/operational-processes/models/movement-head.model'
  import { StorageModel } from '@/models/storage.model'
  import { BranchModel } from '@/models/branch.model'

  export default {
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: MovementHeadModel,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      },
      is_target_storage_error: {
        type: Boolean,
        default: false
      }
    },

    apollo: {
      Suppliers: {
        query: require('../../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
          if (!this.filter_params.storage.id) {
            this.filter_params.setStorage(data.Storages?.[0])
            this.$nextTick(() => this.$emit('change'))
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Branches: {
        query: require('../../gql/getBranches.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.branchList = data.Branches || []
        }
      }
    },

    data: () => ({
      operation_list: [
        {
          name: 'Приход',
          id: 'income'
        }
      ],
      supplier_list: [],
      storage_list: [],
      branchList: [],
      timer: null,
      firstRender: false
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      changeStorage(id) {
        if (this.firstRender) {
          this.$emit('set_is_storage_error', true)
        }
        this.firstRender = true
        this.storage_list.filter((item) => {
          if (item.id.includes(id)) this.filter_params.setStorage(item)
        })

        this.$nextTick(() => this.$emit('change'))
      },
      set_is_storage_error() {
        this.$emit('set_is_storage_error')
      },
      changeTargetStorage(id) {
        const branch = this.branchList.find((el) => el.storage.some((storage) => storage.id === id))
        const storage = new StorageModel(branch.storage.find((storage) => storage.id === id))
        storage.branch = new BranchModel(branch)

        this.filter_params.setTargetStorage(storage)

        this.$nextTick(() => this.$emit('change'))
      },
      setOperation(val) {
        this.filter_params.setOperation(val)
        this.$emit('change')
      },
      setSupplier(val) {
        const supplier = this.supplier_list.find((el) => el.id === val)
        this.filter_params.setSupplier(supplier)
        this.$emit('change')
      },
      setSupplierDocumentId(val) {
        this.filter_params.setSupplierDocumentId(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.filter_params.setDate(this.toNormalDate(val))
        this.$emit('change')
      },
      setSupplierDate(val) {
        this.filter_params.setSupplierDocumentDate(this.toNormalDate(val))
        this.$emit('change')
      },
      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date))
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      .form {
        .col-lg-4 {
          margin-bottom: 16px !important;
        }

        // @media (max-width: 1200px) {}
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
      }

      @media screen and (max-width: 1290px) and (min-width: 1200px) {
        .col-xl-2 {
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
        }
      }
    }

    .option-group {
      border-bottom: 1px #e1e1e1 solid;
      .ivu-select-group {
        padding: 2px 0px 6px 0px;
      }
      .ivu-select-group-title {
        font-weight: 600;
        color: #bcbcbc;
      }
      .ivu-select-item-selected {
        color: #515a6e;
        background: url(/img/icons/accepted.svg) 90% 50% no-repeat;
        transition: 0s;
      }
      .ivu-select-item-selected:hover {
        background-color: #f3f3f3;
      }
      .ivu-select-item {
        padding: 7px 22px;
      }
      .ivu-select-group-title {
        padding-left: 12px;
      }
    }

    .my-dropdown::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    .my-dropdown::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .params-body {
    padding-top: 16px;
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0;
      max-width: 1440px;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-4 {
        margin-bottom: 16px !important;
      }

      // @media (max-width: 1200px) {}
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }

    @media screen and (max-width: 1290px) and (min-width: 1200px) {
      .col-xl-2 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
      }
    }
  }
  .option-group {
    border-bottom: 1px #e1e1e1 solid;
  }
</style>
