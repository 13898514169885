<template>
  <b-modal
    id="modal-costs-add"
    centered
  >
    <template #modal-header>
      <h5>Дополнительные издержки</h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <b-form @submit.stop.prevent="submitPriceUp">
      <div class="d-flex column-block">
        <div class="item-row">
          <label for="cost-add">Сумма издержек, ₽</label>
          <b-form-input
            id="cost-add"
            v-model="costs.value"
            type="number"
          />
        </div>
        <div class="mt-2">
          <h6>Распределение издержек</h6>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="costs.addParams"
              :aria-describedby="ariaDescribedby"
              name="Пропорционально суммам"
              value="sum"
              class="custom-checkbox-centred"
            >
              Пропорционально суммам
            </b-form-radio>
            <b-form-radio
              v-model="costs.addParams"
              :aria-describedby="ariaDescribedby"
              name="Пропорционально количествам"
              value="delta"
              class="custom-checkbox-centred"
            >
              Пропорционально количествам
            </b-form-radio>
          </b-form-group>
        </div>
      </div>
    </b-form>
    <template #modal-footer>
      <div class="d-flex">
        <b-button
          variant="primary"
          :disabled="!(costs.addParams || costs.addParams)"
          @click="submitPriceUp"
        >
          Наценить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    data: () => ({
      costs: {
        addParams: '',
        value: 0
      }
    }),

    methods: {
      submitPriceUp() {
        this.$emit('costs_add', this.costs)
      },

      hideModal() {
        this.costs.value = 0
        this.$bvModal.hide('modal-costs-add')
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .custom-checkbox-centred label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  .column-block {
    flex-direction: column;
  }
  .custom-checkbox-centred {
    display: flex;
  }
  .custom-checkbox-centred label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
</style>
