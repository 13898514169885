var render = function render(){
  var _vm$document$paginati, _vm$document$paginati2, _vm$document, _vm$document$paginati3, _vm$selected$length, _vm$selected, _vm$document$paginati4, _vm$document$paginati5, _vm$document$paginati6, _vm$document$paginati7, _vm$document$paginati8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('modal-costs-add', {
    on: {
      "costs_add": _vm.totalUpdateCosts
    }
  }), _c('modal-price-up', {
    on: {
      "price_up": _vm.totalUpdateMarkup
    }
  }), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.printProducts
    }
  }), _c('div', {
    staticClass: "b-table__content mt-3"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск",
      "is-keyup": true
    },
    on: {
      "input": _vm.search
    }
  }), _c('b-button', {
    staticClass: "btn-more ml-3",
    on: {
      "click": _vm.openSortModal
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/settings.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "table-inner"
  }, [_c('b-table-simple', {
    ref: "movement_table",
    staticClass: "table-docs table-fixed",
    attrs: {
      "items": _vm.document.paginationSpecifications.specification,
      "hover": "",
      "fixed": "",
      "sticky-header": "",
      "no-border-collapse": "",
      "table-class": "movement-table w-100"
    }
  }, [_c('b-thead', [_c('b-tr', _vm._l(_vm.fields.filter(function (el) {
    return el.checked;
  }), function (td, index) {
    return _c('b-th', {
      key: index,
      class: td.class + ' field-column unselectable text-center',
      style: {
        'min-width': td.width + 'px',
        width: td.width + 'px'
      }
    }, [_vm._v(" " + _vm._s(td.label) + " "), td.sortable ? _c('img', {
      staticClass: "cursor img-sorting",
      attrs: {
        "src": "/img/icons/icon-sorting.svg",
        "alt": ""
      }
    }) : _vm._e(), td.key === 'id' ? _c('b-form-checkbox', {
      model: {
        value: _vm.isAllSelected,
        callback: function ($$v) {
          _vm.isAllSelected = $$v;
        },
        expression: "isAllSelected"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "resize-field",
      on: {
        "mousedown": function (el) {
          return _vm.mouseDown(el, td);
        }
      }
    }, [_vm._v("   ")])], 1);
  }), 1)], 1), _c('b-tbody', {
    staticClass: "movement-table-body"
  }, [_vm.busy ? _c('b-tr', [_c('td', {
    attrs: {
      "colspan": _vm.fields.length
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('strong', [_vm._v("Загрузка...")])])], 1)])]) : _vm._e(), _vm._l(_vm.document.paginationSpecifications.specification, function (tr, tr_index) {
    return _c('b-tr', {
      key: tr_index
    }, _vm._l(_vm.fields.filter(function (el) {
      return el.checked;
    }), function (field, index) {
      var _tr$product$retailPri, _tr$product, _tr$cost;

      return _c('b-td', {
        key: index,
        staticStyle: {
          "overflow": "hidden"
        }
      }, [field.key === 'id' ? [_c('b-form-checkbox', {
        staticClass: "cursor",
        attrs: {
          "value": tr.id
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v;
          },
          expression: "selected"
        }
      })] : _vm._e(), field.key === 'order' ? [_vm._v(" " + _vm._s(tr.order) + " ")] : _vm._e(), field.key === 'product' ? [_c('product-spec-info', {
        attrs: {
          "product": tr.product
        }
      })] : _vm._e(), field.key === 'delta' ? [_c('table-row-input', {
        ref: 'delta' + tr.id,
        refInFor: true,
        attrs: {
          "value": tr.delta,
          "fixed": _vm.document.fixed,
          "input_id": 'delta' + tr.id,
          "digits": 3
        },
        on: {
          "handle-input": function (val) {
            return _vm.setDelta(val, tr);
          },
          "input": function (val) {
            return _vm.setDelta(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'measurement' ? [_vm._v(" " + _vm._s(tr.product.measurement.name || '–') + " ")] : _vm._e(), field.key === 'price' ? [_c('table-row-input', {
        attrs: {
          "value": tr.price,
          "fixed": _vm.document.fixed,
          "input_id": 'price' + tr.id,
          "symbol": "₽"
        },
        on: {
          "handle-input": function (val) {
            return _vm.setPrice(val, tr);
          },
          "input": function (val) {
            return _vm.setPrice(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'sum' ? [_c('table-row-input', {
        attrs: {
          "value": tr.sum,
          "fixed": _vm.document.fixed,
          "input_id": 'sum' + tr.id,
          "symbol": "₽"
        },
        on: {
          "handle-input": function (val) {
            return _vm.setSum(val, tr);
          },
          "input": function (val) {
            return _vm.setSum(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'markup' ? [_c('table-row-input', {
        attrs: {
          "value": tr.markup,
          "fixed": _vm.document.fixed,
          "input_id": 'markup' + tr.id,
          "symbol": "%"
        },
        on: {
          "handle-input": function (val) {
            return _vm.setMarkup(val, tr);
          },
          "input": function (val) {
            return _vm.setMarkup(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'retailPrice' ? [_c('table-row-input', {
        attrs: {
          "value": tr.retailPrice,
          "fixed": _vm.document.fixed,
          "input_id": 'retailPrice' + tr.id,
          "symbol": "₽"
        },
        on: {
          "handle-input": function (val) {
            return _vm.setRetailPrice(val, tr);
          },
          "input": function (val) {
            return _vm.setRetailPrice(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'currentPrice' ? [_vm._v(" " + _vm._s(_vm.formatPrice((_tr$product$retailPri = (_tr$product = tr.product) === null || _tr$product === void 0 ? void 0 : _tr$product.retailPrice) !== null && _tr$product$retailPri !== void 0 ? _tr$product$retailPri : 0)) + " ₽ ")] : _vm._e(), field.key === 'nds' ? [_c('table-select-input', {
        attrs: {
          "option_list": _vm.taxRateList,
          "value": tr.nds.id,
          "fixed": _vm.document.fixed,
          "input_id": 'nds' + tr.id,
          "symbol": "₽"
        },
        on: {
          "handle-input": function (val) {
            return _vm.setNds(val, tr);
          },
          "input": function (val) {
            return _vm.setNds(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'taxRateSum' ? [_vm._v(" " + _vm._s(tr.taxRateSum) + " ₽ ")] : _vm._e(), field.key === 'remainders' ? [_vm._v(" " + _vm._s(_vm.getRemainder(tr.product.remainders)) + " ")] : _vm._e(), field.key === 'costs' ? [_vm._v(" " + _vm._s(+((_tr$cost = tr.cost) === null || _tr$cost === void 0 ? void 0 : _tr$cost.toFixed(2)) || 0) + " ")] : _vm._e()], 2);
    }), 1);
  }), ((_vm$document$paginati = _vm.document.paginationSpecifications) === null || _vm$document$paginati === void 0 ? void 0 : (_vm$document$paginati2 = _vm$document$paginati.specification) === null || _vm$document$paginati2 === void 0 ? void 0 : _vm$document$paginati2.length) === 0 && !_vm.busy ? _c('b-tr', [_c('td', {
    attrs: {
      "colspan": _vm.fields.length
    }
  }, [_c('p', {
    staticClass: "center mt-3"
  }, [_vm._v("Нет товаров")])])]) : _vm._e(), !_vm.document.fixed && !_vm.scrollExist ? _c('b-tr', [_c('b-td', {
    attrs: {
      "colspan": _vm.fields.filter(function (el) {
        return el.checked;
      }).length
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [!_vm.document.fixed && !_vm.scrollExist ? _c('product-search', {
    attrs: {
      "document_head": _vm.document.id,
      "document": _vm.document,
      "document_type": 'incoming'
    },
    on: {
      "select_product": _vm.select_product
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _c('movement-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "30px",
      "right": "16px",
      "left": "16px"
    },
    attrs: {
      "items": _vm.isAllSelected ? (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : (_vm$document$paginati3 = _vm$document.paginationSpecifications) === null || _vm$document$paginati3 === void 0 ? void 0 : _vm$document$paginati3.total : (_vm$selected$length = (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.length) !== null && _vm$selected$length !== void 0 ? _vm$selected$length : 0,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "edit_product": _vm.edit_product,
      "remove_items": _vm.removeSpecs,
      "print_price_tags": _vm.printPriceTags
    }
  })], 2)], 1)], 1), _c('div', {
    staticClass: "table-footer movement-table-footer align-middle d-flex align-items-center p-3"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "mr-auto"
  }, [!_vm.document.fixed && _vm.scrollExist ? _c('product-search', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "document_head": _vm.document.id,
      "document": _vm.document,
      "document_type": 'movement'
    },
    on: {
      "select_product": _vm.select_product
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Итого: " + _vm._s((_vm$document$paginati4 = _vm.document.paginationSpecifications) === null || _vm$document$paginati4 === void 0 ? void 0 : _vm$document$paginati4.total) + " ")]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Количество: "), _c('span', [_vm._v(" " + _vm._s(((_vm$document$paginati5 = +((_vm$document$paginati6 = _vm.document.paginationSpecifications) === null || _vm$document$paginati6 === void 0 ? void 0 : (_vm$document$paginati7 = _vm$document$paginati6.factTotal) === null || _vm$document$paginati7 === void 0 ? void 0 : _vm$document$paginati7.toFixed(2))) === null || _vm$document$paginati5 === void 0 ? void 0 : _vm$document$paginati5.toLocaleString('ru-RU')) || 0) + " ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма: "), _c('span', [_vm._v(" " + _vm._s(_vm.formatPrice((_vm$document$paginati8 = _vm.document.paginationSpecifications) === null || _vm$document$paginati8 === void 0 ? void 0 : _vm$document$paginati8.sumTotal)) + " ₽ ")])])])]), _c('sort-table-modal', {
    attrs: {
      "fields": _vm.copyFields,
      "default_fields": _vm.default_fields
    },
    on: {
      "change": _vm.changeFields,
      "set-default": _vm.setDefault
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "45px"
    }
  }, [_c('span')]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "45px"
    }
  }, [_c('span')]);

}]

export { render, staticRenderFns }