import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
import { ProductModel } from '@/models/product.model'
import { TaxRateModel } from '@/models/tax-rate.model'
import { SerialModel } from '@/models/serial.model'

export class MovementSpecificationModel {
  constructor(request = {}) {
    this.delta = request?.delta
    this.document_head = new OutgoingHeadModel(request?.document_head)
    this.id = request?.id
    this.order = request?.order
    this.price = request?.price
    this.product = new ProductModel(request?.product)
    this.nds = new TaxRateModel(request?.nds || this.product.taxRate)
    this.retailPrice = request?.retailPrice || 0
    this.serial = new SerialModel(request?.serial)
    this.sum = request?.sum || this.price * this.delta || 0
  }

  get taxRateSum() {
    return Number((this.sum * (1 - 1 / (1 + this.nds.value) || 0)).toFixed(2))
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = this.price * this.delta
  }

  setSum = (val) => {
    this.sum = val
    this.price = Number((this.sum / this.delta).toFixed(2))
  }

  setPrice = (val) => {
    this.price = val
    this.sum = this.price * this.delta
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      delta: +this.delta,
      price: +this.price,
      retailPrice: +this.product.retailPrice,
      nds: this.product.taxRate.id,
      sum: +this.sum
    }
  }

  // FOR CASHLESS

  get cashless_input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      delta: +this.delta,
      price: +this.price,
      retailPrice: +this.retailPrice,
      nds: this.product.taxRate.id,
      sum: +this.sum
    }
  }
  setCashlessDelta = (val) => {
    this.delta = val
    this.sum = this.price * this.delta
  }

  setRetailPrice = (price) => {
    this.retailPrice = price
  }

  get retailSum() {
    return +(this.retailPrice * this.delta).toFixed(2)
  }

  setRetailSum = (val) => {
    this.retailPrice = +(val / this.delta).toFixed(2)
  }
}
