var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "overhead-document"
    }
  }, [_c('movement-head', {
    attrs: {
      "document": _vm.movementDocument,
      "is_storage_error": _vm.is_storage_error,
      "is_target_storage_error": _vm.is_target_storage_error,
      "loading": _vm.loading
    },
    on: {
      "set_is_storage_error": _vm.set_is_storage_error,
      "onChange": _vm.updateHead,
      "makeUsed": _vm.makeUsed
    }
  }), _c('movement-spec-table', {
    attrs: {
      "document": _vm.movementDocument,
      "busy": _vm.tableIsBusy
    },
    on: {
      "set_is_storage_error": _vm.set_is_storage_error,
      "update_table": _vm.refetchData,
      "scroll-down": _vm.scrollDown,
      "scroll-up": _vm.scrollUp,
      "get-last": _vm.getLast,
      "get_by_order": _vm.getByOrder,
      "update-data": _vm.updateData,
      "storage_error": _vm.setStorageError,
      "search": _vm.search
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }