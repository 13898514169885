<template>
  <div
    v-click-outside="clickOutside"
    class="count"
    @click="showInput"
  >
    <div
      v-show="!is_edit"
      :class="{ cursor: !fixed }"
    >
      {{ optionName }}
      <img
        v-if="!fixed"
        class="edit"
        src="/img/icons/icon_pencil_edit.svg"
        alt=""
      />
    </div>
    <e-select
      v-if="is_edit"
      ref="mes_select"
      v-model="selected_mes"
      select_name="mes_select"
      selected_type="text"
      position="bottom"
      :value="selected_mes"
      :options="option_list"
      :serchable="false"
      :show_bottom_button="add_button"
      @click_bottom="clickBottom"
      @select="handleInput"
    >
      <template
        v-if="add_button"
        #bottom_button_text
      >
        Упаковку
      </template>
    </e-select>
    <!--    <i-select-->
    <!--      v-if="is_edit"-->
    <!--      ref="i-select-input"-->
    <!--      v-model="new_value"-->
    <!--      element-id="select-input"-->
    <!--      transfer-->
    <!--      @on-change="handleInput"-->
    <!--      @on-open-change="hideInput"-->
    <!--    >-->
    <!--      <i-option-->
    <!--        v-for="item in option_list"-->
    <!--        :key="item.id"-->
    <!--        :value="item.id"-->
    <!--      >-->
    <!--        {{ item.name }}-->
    <!--      </i-option>-->
    <!--    </i-select>-->
  </div>
</template>

<script>
  export default {
    name: 'TableSelectInput',
    props: {
      add_button: {
        type: Boolean,
        default: false
      },
      tr: {
        type: Object,
        default: () => {}
      },
      fixed: {
        type: Boolean,
        default: true
      },
      value: {
        type: String,
        default: ''
      },
      option_list: {
        type: Array,
        default: () => []
      },
      product: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        selected_mes: [],
        new_value: '',
        is_edit: false
      }
    },
    computed: {
      optionName() {
        return this.option_list?.find((el) => el.id === this.value)?.name ?? '-'
      },
      selectedValue() {
        return this.option_list?.find((el) => el.id === this.value)
          ? [this.option_list?.find((el) => el.id === this.value)]
          : []
      }
    },

    watch: {
      value() {
        if (!this.selected_mes?.find((el) => el.id === this.value)) {
          this.selected_mes = this.selectedValue
        }
      }
    },
    updated() {
      if (!this.selected_mes?.find((el) => el.id === this.value)) {
        this.selected_mes = this.selectedValue
      }
    },
    methods: {
      clickBottom() {
        this.$emit('click-bottom', { product: this.product, tr: this.tr })
      },
      showInput() {
        if (this.fixed) return
        this.is_edit = true
        this.new_value = this.value
        setTimeout(() => {
          this.$refs['mes_select']?.focusInput()
          this.$refs['mes_select']?.fullWidth()
        }, 50)
      },
      handleInput(value) {
        const option = this.option_list?.find((el) => el.id === value.id)
        this.$emit('handle-input', option)
        this.is_edit = false
      },
      clickOutside() {
        this.is_edit = false
      },
      hideInput(val) {
        if (!val) setTimeout(() => (this.is_edit = false), 250)
      }
    }
  }
</script>

<style scoped lang="scss">
  .input {
    width: 100%;
  }
  .count {
    position: relative;
    display: inline-block;
    padding: 6px;

    .cursor {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-left: 10px;
        opacity: 0;
      }
    }

    &:hover {
      background: #efefef;

      img {
        opacity: 1;
      }
    }

    img {
      &.enter {
        position: absolute;
        top: 11px;
        right: 11px;
      }
    }
  }
  :deep .bottom-button {
    padding: 3px 12px !important;
  }
  :deep .ms__dropdown-item.active {
    p {
      color: var(--blue400);
    }
  }
  :deep .ms__dropdown {
    ul {
      max-height: 170px !important;
    }
    width: fit-content !important;
  }
  :deep .ms {
    max-width: 90px;
  }
</style>
