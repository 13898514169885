var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutside,
      expression: "clickOutside"
    }],
    staticClass: "count",
    on: {
      "click": _vm.showInput
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.is_edit,
      expression: "!is_edit"
    }],
    class: {
      cursor: !_vm.fixed
    }
  }, [_vm._v(" " + _vm._s(_vm.optionName) + " "), !_vm.fixed ? _c('img', {
    staticClass: "edit",
    attrs: {
      "src": "/img/icons/icon_pencil_edit.svg",
      "alt": ""
    }
  }) : _vm._e()]), _vm.is_edit ? _c('e-select', {
    ref: "mes_select",
    attrs: {
      "select_name": "mes_select",
      "selected_type": "text",
      "position": "bottom",
      "value": _vm.selected_mes,
      "options": _vm.option_list,
      "serchable": false,
      "show_bottom_button": _vm.add_button
    },
    on: {
      "click_bottom": _vm.clickBottom,
      "select": _vm.handleInput
    },
    scopedSlots: _vm._u([_vm.add_button ? {
      key: "bottom_button_text",
      fn: function () {
        return [_vm._v(" Упаковку ")];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.selected_mes,
      callback: function ($$v) {
        _vm.selected_mes = $$v;
      },
      expression: "selected_mes"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }