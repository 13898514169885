var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "cash-deatil-prossuct"
    }
  }, [_c('b-card', {
    staticClass: "p-0 mt-3",
    staticStyle: {
      "flex": "1",
      "border": "none"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "table-actions p-3 d-flex"
  }, [_c('input', {
    staticClass: "b-input-search mr-3",
    attrs: {
      "type": "text",
      "placeholder": "Найти"
    },
    on: {
      "input": function (e) {
        return _vm.filterItems(e.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "table-inner"
  }, [_c('resizable-table', {
    directives: [{
      name: "table-resize",
      rawName: "v-table-resize"
    }],
    ref: "table_cashbox_sales",
    staticClass: "table-sales-checks",
    attrs: {
      "table_name": "table_cashbox_sales",
      "default_fields": _vm.fields,
      "items": _vm.products,
      "busy": _vm.is_loading
    },
    on: {
      "resize": _vm.resizeTable
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "th-wrapper d-flex justify-content-center"
        }, [_c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('div', {
          staticClass: "table-inner__left-text"
        }, [_c('p', {
          staticClass: "table-inner__name"
        }, [_vm._v(_vm._s(data.item.product.name))]), _c('p', {
          staticClass: "table-inner__articul"
        }, [_vm._v(_vm._s(data.item.product.articul))])])];
      }
    }, {
      key: "body_remainders",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getRemainders(data.item.product.remainders)) + " ")];
      }
    }, {
      key: "body_delta",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.delta) + " ")];
      }
    }, {
      key: "body_price",
      fn: function (data) {
        return [_c('div', {
          staticClass: "table-inner__right-text"
        }, [_vm._v(_vm._s(_vm.formatPrice(data.item.retailPrice, 2) || 0) + " ₽")])];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_c('div', {
          staticClass: "table-inner__right-text"
        }, [_vm._v(_vm._s(_vm.formatPrice(data.item.retailSum, 2) || 0) + " ₽")])];
      }
    }, {
      key: "body_nds_name",
      fn: function (data) {
        var _data$item, _data$item$product, _data$item$product$ta;

        return [_vm._v(" " + _vm._s(((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$product = _data$item.product) === null || _data$item$product === void 0 ? void 0 : (_data$item$product$ta = _data$item$product.taxRate) === null || _data$item$product$ta === void 0 ? void 0 : _data$item$product$ta.name) || '-') + " ")];
      }
    }, {
      key: "body_nds_sum",
      fn: function (data) {
        return [_c('div', {
          staticClass: "table-inner__right-text"
        }, [_vm._v(_vm._s(_vm.calcNdsSumm(data.item)) + " ₽")])];
      }
    }, {
      key: "body_retail_price",
      fn: function (data) {
        return [_c('div', {
          staticClass: "table-inner__right-text"
        }, [_vm._v(_vm._s(_vm.formatPrice(data.item.product.retailPrice, 2) || 0) + " ₽")])];
      }
    }, {
      key: "body_barcode",
      fn: function (data) {
        var _data$item2, _data$item2$product, _data$item2$product$p, _data$item2$product$p2, _data$item2$product$p3;

        return [_c('div', {
          staticClass: "table-inner__left-text"
        }, [_vm._v(" " + _vm._s((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$product = _data$item2.product) === null || _data$item2$product === void 0 ? void 0 : (_data$item2$product$p = _data$item2$product.package) === null || _data$item2$product$p === void 0 ? void 0 : (_data$item2$product$p2 = _data$item2$product$p[0]) === null || _data$item2$product$p2 === void 0 ? void 0 : (_data$item2$product$p3 = _data$item2$product$p2.barcode) === null || _data$item2$product$p3 === void 0 ? void 0 : _data$item2$product$p3.barcode) + " ")])];
      }
    }, {
      key: "body_manufacturer",
      fn: function (data) {
        var _data$item3, _data$item3$product, _data$item3$product$s, _data$item4, _data$item4$product, _data$item4$product$s;

        return [_c('div', {
          staticClass: "table-inner__left-text"
        }, [_vm._v(" " + _vm._s(((_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : (_data$item3$product = _data$item3.product) === null || _data$item3$product === void 0 ? void 0 : (_data$item3$product$s = _data$item3$product.supplier) === null || _data$item3$product$s === void 0 ? void 0 : _data$item3$product$s.full_name) || ((_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : (_data$item4$product = _data$item4.product) === null || _data$item4$product === void 0 ? void 0 : (_data$item4$product$s = _data$item4$product.supplier) === null || _data$item4$product$s === void 0 ? void 0 : _data$item4$product$s.name) || '-') + " ")])];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }