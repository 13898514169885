<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <b-button
          size="m"
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            class="ml-3"
            :class="{ rotate: visibleParams }"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!document.fixed"
          size="m"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить черновик
        </e-button>
        <e-button
          size="m"
          variant="primary"
          class="cursor ml-3"
          :disabled="loading"
          :loading="loading"
          @click="updateDocument(document.fixed)"
        >
          <template v-if="is_request_update">
            <b-spinner
              variant="light"
              small
            />
          </template>
          <template v-else>
            {{ document.fixed ? 'Редактировать' : 'Провести' }}
          </template>
        </e-button>
      </div>
    </div>
    <e-collapse
      id="collapse-filters"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <filters-movement
          ref="header_filters_movement"
          :fixed="document?.fixed"
          :filter_params="document"
          :is_storage_error="is_storage_error"
          :is_target_storage_error="is_target_storage_error"
          @set_is_storage_error="set_is_storage_error"
          @change="change"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import FiltersMovement from '@/views/operational-processes/components/movement/FiltersMovement'
  import { MovementHeadModel } from '@/views/operational-processes/models/movement-head.model'

  export default {
    name: 'MovementHead',
    components: {
      FiltersMovement
    },
    props: {
      document: {
        type: MovementHeadModel
      },
      loading: {
        type: Boolean,
        default: false
      },
      is_storage_error: Boolean,
      is_target_storage_error: Boolean
    },
    data() {
      return {
        visibleParams: false,
        isSupplierError: false,
        isStorageError: false,
        is_request_update: false,
        isTargetStorageError: false
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      updateDocument(status) {
        if (!status && !this.document.supplier.id) this.isSupplierError = true
        if (!status && !this.document.storage.id) this.isStorageError = true
        this.$emit('makeUsed', !status)
      },
      set_is_storage_error(el) {
        this.$emit('set_is_storage_error', el)
      },
      makeFixed(status) {
        this.$emit('makeUsed', status)
      },
      back() {
        this.$emit('onChange')
        this.$router.back()
      },
      change() {
        if (this.document.supplier.id) this.isSupplierError = false
        if (this.document.storage.id) this.isStorageError = false
        this.$emit('onChange')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .b-table__filter {
    .b-toggle-button {
      button {
        background: #e2e2e2;
        color: #313131;

        img {
          margin-right: 0;
          transition: all 0.2s;
        }

        &.not-collapsed {
          img {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .table-filter {
    button {
      background: #efefef;
      color: #313131;

      &:hover {
        background: #efefef;
        color: #313131;
      }
    }
  }
  ::v-deep .elm-calendar input {
    height: 31px;
  }
  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
