import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { StorageModel } from '@/models/storage.model'
import { SupplierModel } from '@/models/supplier.model'
import { OutgoingSpecificationPaginationModel } from '@/views/operational-processes/models/outgoing-spec-pagination.model'
import { UserModel } from '@/models/user.model'
import { OutgoingSpecificationModel } from '@/views/operational-processes/models/outgoing-spec.model'
import { EntityModel } from '@/models/entity.model'

export class MovementHeadModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request?.branch)
    this.targetBranch = new BranchModel(request?.targetBranch)
    this.comment = request?.comment
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.status === 'confirm'
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.operation = new OperationModel(request?.operation)
    this.owner = new UserModel(request?.owner)
    this.paginationSpecifications = new OutgoingSpecificationPaginationModel(request?.paginationSpecifications)
    this.entity = new EntityModel(request?.entity)
    this.retailSum = request?.retailSum
    this.storage = new StorageModel(request?.storage)
    this.targetStorage = new StorageModel(request?.targetStorage)
    this.sum = request?.sum
    this.supplier = new SupplierModel(request?.supplier)
    this.status = request?.status
  }

  setSpec(specIndex, incomingSpecificationElement) {
    if (specIndex < 0) return
    const specification = this.paginationSpecifications.specification?.map((el) => new OutgoingSpecificationModel(el))
    this.paginationSpecifications.sumTotal -= specification[specIndex].sum
    this.paginationSpecifications.factTotal -= specification[specIndex].delta
    specification[specIndex] = new OutgoingSpecificationModel(incomingSpecificationElement)
    this.paginationSpecifications.sumTotal += specification[specIndex].sum
    this.paginationSpecifications.factTotal += specification[specIndex].delta

    this.paginationSpecifications = new OutgoingSpecificationPaginationModel({
      ...this.paginationSpecifications,
      specification: specification
    })
  }

  setStorage = (val) => {
    this.storage = val
  }

  setEntity = (val) => {
    this.entity = val
  }

  setTargetStorage = (val) => {
    this.targetBranch = val.branch
    this.targetStorage = val
  }

  setSupplier = (val) => {
    this.supplier = val
  }

  setOperation = (val) => {
    this.operation.name = val
  }

  setDate = (val) => {
    this.date = new Date(val)
  }

  setComment = (val) => {
    this.comment = val
  }
  get input() {
    return {
      branch: this.branch?.id,
      targetBranch: this.targetBranch?.id,
      targetStorage: this.targetStorage?.id,
      comment: this.comment,
      date: this.date,
      entity: this.entity?.id,
      id: this.id,
      name: this.name,
      storage: this.storage?.id,
      supplier: this.supplier.id,
      operation: this.operation?.name
    }
  }
}
