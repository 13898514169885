<template>
  <section id="cash-deatil-prossuct">
    <b-card
      class="p-0 mt-3"
      style="flex: 1; border: none"
      no-body
    >
      <div class="table-actions p-3 d-flex">
        <input
          type="text"
          class="b-input-search mr-3"
          placeholder="Найти"
          @input="(e) => filterItems(e.target.value)"
        />
      </div>
      <div class="table-inner">
        <resizable-table
          ref="table_cashbox_sales"
          v-table-resize
          table_name="table_cashbox_sales"
          :default_fields="fields"
          :items="products"
          :busy="is_loading"
          class="table-sales-checks"
          @resize="resizeTable"
        >
          <template #head_id>
            <b-form-checkbox v-model="select_all" />
          </template>

          <template #body_id="data">
            <div class="th-wrapper d-flex justify-content-center">
              <b-form-checkbox
                v-model="selected"
                class="cursor"
                :value="data.value"
              />
            </div>
          </template>

          <template #body_product="data">
            <div class="table-inner__left-text">
              <p class="table-inner__name">{{ data.item.product.name }}</p>
              <p class="table-inner__articul">{{ data.item.product.articul }}</p>
            </div>
          </template>

          <template #body_remainders="data">
            {{ getRemainders(data.item.product.remainders) }}
          </template>

          <template #body_delta="data">
            {{ data.item.delta }}
          </template>

          <template #body_price="data">
            <!-- <div class="table-inner__right-text">{{ data.value || 0 }} ₽</div> -->
            <div class="table-inner__right-text">{{ formatPrice(data.item.retailPrice, 2) || 0 }} ₽</div>
          </template>

          <template #body_sum="data">
            <div class="table-inner__right-text">{{ formatPrice(data.item.retailSum, 2) || 0 }} ₽</div>
          </template>

          <template #body_nds_name="data">
            {{ data.item?.product?.taxRate?.name || '-' }}
          </template>

          <template #body_nds_sum="data">
            <div class="table-inner__right-text">{{ calcNdsSumm(data.item) }} ₽</div>
          </template>

          <template #body_retail_price="data">
            <div class="table-inner__right-text">{{ formatPrice(data.item.product.retailPrice, 2) || 0 }} ₽</div>
          </template>

          <template #body_barcode="data">
            <div class="table-inner__left-text">
              {{ data.item?.product?.package?.[0]?.barcode?.barcode }}
            </div>
          </template>

          <template #body_manufacturer="data">
            <div class="table-inner__left-text">
              {{ data.item?.product?.supplier?.full_name || data.item?.product?.supplier?.name || '-' }}
            </div>
          </template>
        </resizable-table>
      </div>
    </b-card>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { formatMoney, formatRemainder } from '@/utils/formatMoney'

  export default {
    props: {
      products: {
        type: Array,
        default: () => []
      },

      is_loading: {
        type: Boolean,
        default: true
      }
    },

    data: function () {
      return {
        select_all: [],
        selected: [],
        data_range: ''
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            class: 'center p-0 td-id',
            width: 50,
            thStyle: 'width: 50px',
            checked: true
          },
          {
            key: 'product',
            label: 'Наименование товара',
            width: 300,
            sortable: true,
            checked: true
          },
          {
            key: 'remainders',
            label: 'Остаток',
            class: 'center',
            width: 120,
            sortable: true,
            formatter: formatMoney,
            checked: true
          },
          {
            key: 'delta',
            label: 'Общее количество',
            class: 'center',
            width: 120,
            sortable: true,
            formatter: formatMoney,
            checked: true
          },
          {
            key: 'price',
            label: 'Цена',
            class: 'center',
            width: 120,
            sortable: true,
            formatter: formatMoney,
            checked: true
          },
          {
            key: 'sum',
            label: 'Сумма',
            class: 'center',
            width: 120,
            sortable: true,
            checked: true
          },
          {
            key: 'nds_name',
            label: 'Ставка НДС',
            class: 'center',
            width: 120,
            sortable: true,
            checked: true
          },
          {
            key: 'nds_sum',
            label: 'Сумма НДС',
            class: 'center',
            width: 120,
            sortable: true,
            checked: true
          },
          {
            key: 'retail_price',
            label: 'Розница текущая',
            class: 'center',
            width: 120,
            sortable: true,
            checked: true
          },
          {
            key: 'manufacturer',
            label: 'Поставщик',
            class: 'center',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            key: 'barcode',
            label: 'Штрих-код товара',
            class: 'center',
            width: 150,
            sortable: true,
            checked: true
          }
        ]
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.checks.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },

    methods: {
      resizeTable() {},
      calcNdsSumm(item) {
        return Number(item.sum * (item.product.taxRate?.value || 0)).toFixed(2)
      },
      formatPrice(val, count) {
        return formatMoney(val, count)
      },
      getRemainders(val) {
        let rems = 0
        val?.forEach((el) => (rems += el.value || 0))
        return formatRemainder(rems)
      },
      filterItems(val) {
        this.$emit('filter_products', val)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .head-field {
      padding: 5px;
    }

    .row-field {
      border-bottom: 1px solid #e1e1e1;
      border-right: none;
    }
  }

  .table-inner {
    &__left-text {
      text-align: left;
    }
    &__right-text {
      text-align: right;
    }
    &__name {
      margin: 0px !important;
    }
    &__articul {
      margin: 0 !important;
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }

  #cash-deatil-prossuct {
    display: flex;
    flex-direction: column;
    flex: 1;
    .b-input-search {
      max-width: 100%;
      width: 268px;
    }

    .table-inner {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-width: 100%;
    }

    .table-sales-checks {
      margin-bottom: -1px !important;
    }
  }

  .table-actions {
    border: 1px solid #e1e1e1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: none;
  }
</style>
