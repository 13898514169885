var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-costs-add",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Дополнительные издержки")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !(_vm.costs.addParams || _vm.costs.addParams)
          },
          on: {
            "click": _vm.submitPriceUp
          }
        }, [_vm._v(" Наценить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.submitPriceUp.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex column-block"
  }, [_c('div', {
    staticClass: "item-row"
  }, [_c('label', {
    attrs: {
      "for": "cost-add"
    }
  }, [_vm._v("Сумма издержек, ₽")]), _c('b-form-input', {
    attrs: {
      "id": "cost-add",
      "type": "number"
    },
    model: {
      value: _vm.costs.value,
      callback: function ($$v) {
        _vm.$set(_vm.costs, "value", $$v);
      },
      expression: "costs.value"
    }
  })], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('h6', [_vm._v("Распределение издержек")]), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio', {
          staticClass: "custom-checkbox-centred",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "Пропорционально суммам",
            "value": "sum"
          },
          model: {
            value: _vm.costs.addParams,
            callback: function ($$v) {
              _vm.$set(_vm.costs, "addParams", $$v);
            },
            expression: "costs.addParams"
          }
        }, [_vm._v(" Пропорционально суммам ")]), _c('b-form-radio', {
          staticClass: "custom-checkbox-centred",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "Пропорционально количествам",
            "value": "delta"
          },
          model: {
            value: _vm.costs.addParams,
            callback: function ($$v) {
              _vm.$set(_vm.costs, "addParams", $$v);
            },
            expression: "costs.addParams"
          }
        }, [_vm._v(" Пропорционально количествам ")])];
      }
    }])
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }