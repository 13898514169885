<template>
  <div
    v-if="items > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear_selected"
    />
    {{ items }}
    {{ item_name }}
    <div class="b-btns">
      <b-button
        variant="dark"
        @click="removeItem"
      >
        Удалить
      </b-button>
      <b-button
        variant="dark"
        @click="printPriceTags"
      >
        Печать
      </b-button>
      <b-button
        v-if="items === 1"
        variant="dark"
        @click="editProduct"
      >
        Редактировать товар
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Number,
        default: () => 0
      },
      clear_selected: {
        type: Function,
        default: () => {}
      }
    },

    computed: {
      item_name() {
        if ((this.items % 100) % 10 === 1) return 'Товар'
        if ((this.items % 100) % 10 > 1 && (this.items % 100) % 10 < 5) return 'Товара'
        return 'Товаров'
      }
    },

    methods: {
      editProduct() {
        this.$emit('edit_product')
      },
      removeItem() {
        this.$emit('remove_items')
      },

      printPriceTags() {
        this.$emit('print_price_tags')
      },

      PriceUp() {
        this.$bvModal.show('modal-price-up')
      },
      CostsAdd() {
        this.$bvModal.show('modal-costs-add')
      }
    }
  }
</script>
