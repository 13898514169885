var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card pb-0 pr-0 mb-0"
  }, [_c('b-form', {
    staticClass: "form pr-0"
  }, [_c('b-row', {
    staticClass: "w-100 align-items-end"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата",
      "label-for": "work_period"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.filter_params.date)) + " ")] : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "placeholder": "Выберите дату",
      "value": _vm.filter_params.date,
      "format": "dd.MM.yyyy",
      "clearable": false,
      "is-keyup": true,
      "transfer": ""
    },
    on: {
      "on-change": _vm.setDate
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_storage_error
    },
    attrs: {
      "label": "Отправитель",
      "label-for": "storage"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.storage.name) + " ")] : [_c('i-select', {
    attrs: {
      "id": "storage",
      "value": _vm.filter_params.storage.id,
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeStorage
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_target_storage_error
    },
    attrs: {
      "label": "Получатель",
      "label-for": "storage"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.targetStorage.name) + " ")] : [_c('i-select', {
    attrs: {
      "id": "targetStorage",
      "transfer-class-name": "my-dropdown",
      "value": _vm.filter_params.targetStorage.id,
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeTargetStorage
    }
  }, _vm._l(_vm.branchList, function (item) {
    return _c('i-option-group', {
      key: item.id,
      staticClass: "option-group",
      attrs: {
        "value": item.id,
        "label": item.name
      }
    }, _vm._l(item.storage, function (el) {
      return _c('i-option', {
        key: el.id,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1);
  }), 1)]], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor mb-3"
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row mb-3",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "col": "",
      "xl": "4",
      "lg": "4",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "value": _vm.filter_params.comment,
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true
    },
    on: {
      "input": _vm.setComment
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }